"use client";
import TravelarCounter from "@/components/homeComponent/banner/TravelarCounter";
// import { IoIosSearch } from "react-icons/io";
// import { LiaPlaneDepartureSolid } from "react-icons/lia";
import usBangla from "../../../assets/flightsHome/usBangla.png";
import bimanbd from "../../../assets/flightsHome/biman-bangla.png";
import emirates from "../../../assets/flightsHome/emirates.png";
import qatarAirways from "../../../assets/flightsHome/qatar-airways.png";
import singaporeAir from "../../../assets/flightsHome/singapore-airlines.png";
import Onway from "@/components/homeComponent/banner/OnWay";
import RoundWay from "@/components/homeComponent/banner/Return";
import Multicity from "@/components/homeComponent/banner/Multicity";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";

const FlightBanner = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [travelerCounts, setTravelerCounts] = useState({
    adults: 1,
    children: 0,
    kids: 0,
    infants: 0,
  });

  const [departureAirports, setDepartureAirports] = useState([
    { name: "", code: "" },
  ]);
  const [arrivalAirports, setArrivalAirports] = useState([
    { name: "", code: "" },
  ]);
  const [departureDates, setDepartureDates] = useState([""]);

  // console.log(departureAirports, arrivalAirports, departureDates);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const [type, setType] = useState("oneway");

  // useEffect(() => {
  //   const getValueOfTravelType = localStorage.getItem("type");
  //   if (getValueOfTravelType) {
  //     setType(getValueOfTravelType);
  //   }
  // }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the dropdown and button
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTypeChange = (wayType) => {
    const selectedType = wayType.toLowerCase();
    setType(selectedType);
    localStorage.setItem("type", selectedType);
    setIsDropdownOpen(false);
    const event = new Event("typeChange");
    window.dispatchEvent(event);
  };

  return (
    <div
      style={{
        backgroundImage: `url('https://i.ibb.co.com/bQMK0c3/HAMS-fly-Banner.webp')`,
      }}
      className="h-auto lg:min-h-[680px] bg-cover bg-center bg-no-repeat relative w-full"
    >
      <span className="absolute w-full h-full bg-black/20"></span>
      <div className="relative flex flex-col items-center pt-36 pb-8 justify-center z-50 px-3 md:px-7">
        <div className="text-white text-center mt-5 space-y-4">
          <h1 className="lg:text-4xl md:text-2xl text-lg font-semibold lg:block md:block hidden">
            Finds the cheap flights with the right price
          </h1>
          <p className="lg:text-lg md:text-sm lg:block md:block hidden font-medium">
            Best Flight deal to around the globe
          </p>
          <div className="w-full sm:w-2/3 lg:w-auto bg-black/50 mx-auto py-6 px-4 sm:px-8 rounded">
            <div className="md:w-[80%] lg:w-full mx-auto">
              <div>
                <div>
                  <div className="flex gap-1.5 mb-2 w-auto">
                    <div className="relative">
                      {/* Toggle Button */}
                      <div
                        className="md:w-full flex gap-2 bg-[#e1eee8] text-black text-xs justify-center items-center text-center py-1 px-2 mb-1 rounded-xl cursor-pointer"
                        ref={buttonRef}
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      >
                        <h3 className="capitalize">{type}</h3>
                        {isDropdownOpen ? (
                          <MdOutlineKeyboardArrowUp fontSize={20} />
                        ) : (
                          <MdOutlineKeyboardArrowDown fontSize={20} />
                        )}
                      </div>

                      {/* Traveler Dropdown */}
                      {isDropdownOpen && (
                        <div
                          ref={dropdownRef}
                          className="mx-auto bg-white text-black rounded-lg overflow-hidden shadow-lg w-full md:w-64 absolute left-0  z-10"
                        >
                          {["Oneway", "Roundway", "Multi City"].map(
                            (way, index, array) => {
                              return (
                                <div key={way}>
                                  <div className="flex items-center ">
                                    <button
                                      onClick={() => handleTypeChange(way)}
                                      className={`p-3 text-sm  duration-100 border-b ${
                                        index === array.length - 1
                                          ? "border-b-0"
                                          : ""
                                      } hover:bg-gray-200 w-full text-left cursor-pointer`}
                                    >
                                      {way}
                                    </button>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>
                    <TravelarCounter
                      travelerCounts={travelerCounts}
                      setTravelerCounts={setTravelerCounts}
                    />
                  </div>
                </div>
                {type === "oneway" && (
                  <Onway
                    travelerCounts={travelerCounts}
                    departureAirports={departureAirports}
                    setDepartureAirports={setDepartureAirports}
                    arrivalAirports={arrivalAirports}
                    setArrivalAirports={setArrivalAirports}
                    departureDates={departureDates}
                    setDepartureDates={setDepartureDates}
                  />
                )}
                {type === "roundway" && (
                  <RoundWay
                    travelerCounts={travelerCounts}
                    departureAirports={departureAirports}
                    setDepartureAirports={setDepartureAirports}
                    arrivalAirports={arrivalAirports}
                    setArrivalAirports={setArrivalAirports}
                    departureDates={departureDates}
                    setDepartureDates={setDepartureDates}
                  />
                )}
                {type === "multi city" && (
                  <Multicity travelerCounts={travelerCounts} />
                )}
              </div>
              {/* <div className="text-left flex items-center gap-2">
                <input type="checkbox" name="" id="" />{" "}
                <span>Check hotels with HAMS</span>
              </div> */}
              <div className="flex mt-5 md:ml-2 gap-1 md:gap-7">
                {/* <div className="flex items-center gap-1 relative cursor-pointer">
                  <input
                    value="nearby airports"
                    // defaultChecked
                    type="radio"
                    name="transport"
                    id="nearby airports"
                  />
                  <p className="text-sm text-white font-medium ">
                    Nearby Airports
                  </p>
                </div> */}

                {/* <div className="flex items-center gap-1 relative cursor-pointer">
                  <input
                    value="Prefer nonstop"
                    type="radio"
                    name="transport"
                    id="Prefer nonstop"
                  />
                  <p className="text-sm text-white font-medium text-center">
                    Prefer nonstop
                  </p>
                </div> */}
              </div>
            </div>
          </div>

          {/* <p className="lg:text-lg md:text-sm lg:block md:block hidden font-medium max-w-[500px] mx-auto">
            Discover with HAMS more than thousand of partners to bring you
            better travel deals.
          </p>

          <div className="flex flex-wrap items-center justify-center gap-8 w-[90%] mx-auto md:w-full">
            <Image alt="us-bangla" src={usBangla} />
            <Image alt="us-bangla" src={emirates} />
            <Image alt="us-bangla" src={singaporeAir} />
            <Image alt="us-bangla" src={qatarAirways} />
            <Image alt="us-bangla" src={bimanbd} />
          </div> */}
        </div>
      </div>
      {/* <LoginMsg /> */}
    </div>
  );
};

export default FlightBanner;
